import PromoteMessage from './promote-message';
import Member from './member';
import Statistics from './statistics';
import Visit from './visit';
export default {
    PromoteMessage,
    Member,
    Statistics,
    Visit,
} as any;
