class StorageService {
    getItem(key: string) {
        return window.localStorage.getItem(key);
    }
    removeItem(key: string) {
        window.localStorage.removeItem(key);
    }
    setItem(key: string, value: string | number) {
        window.localStorage.setItem(key, String(value));
    }
    clear() {
        window.localStorage.clear();
    }
}

export const storageService = new StorageService();
