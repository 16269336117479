/**ct
 * 后端：yank;
 * 前端：xmen;
 * 登录接口地址：http://gw.dev.douqiu.run/doc.html#/qiutx-passport/OAuth2%E7%9B%B8%E5%85%B3%E6%93%8D%E4%BD%9C/agentLoginUsingPOST
 */
import React, { useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import QRCode  from 'qrcode.react';
import './style/promote-message.less';
import  {message} from 'antd';
import {httpService} from '../common/services/http.service';
import { storageService } from '../common/services/storage.service'

const PromoteMessage = (props:any) => {
    let[pageData,setpageData] =useState({spreadUrl:'',inviteCode:''})
     let getPageData=()=>{
         let uid = storageService.getItem('uid');
         httpService.ajax({
             url:'/qiutx-support/v1/agent/promotion/info',
             method: 'get',
             headers:uid?{
                 'x-user-header':JSON.stringify({ uid })
             }:{}
         }).subscribe((res:any)=>{
             if(res.code==200){
                 setpageData(res.data)
             }
         })
     }
     let copyText=(data:string)=>{
         copy(data)
         message.success(`复制成功:${data}`);
     }
    useEffect(()=>{
        getPageData();
    },[])
    let downloadQrcode=()=>{
        let canvasImg = document.getElementsByTagName('canvas')[0];
        let image = new Image();
        image.src = canvasImg.toDataURL("image/png");
        let link = document.createElement('a')
        link.href = image.src;
        let agentName= storageService.getItem('agentName')
        link.download = `推广二维码_${agentName}`;
        link.click();
    }
    return (
        <div className="promote-message-page">
            <div className='address-box box'>
                <span className='title-text text'>推广地址：</span>
                <span className='text text_address' >{pageData.spreadUrl}</span>
                {pageData.spreadUrl&&<span className='copy' onClick={() => copyText(pageData.spreadUrl)}>复制</span>}
            </div>
            <div className='invite-box box'>
                <span className='title-text text'>邀请码：</span>
                <span className='text text_invite' >{pageData.inviteCode}</span>
                {pageData.inviteCode&&<span className='copy' onClick={() => copyText(pageData.inviteCode)}>复制</span>}
            </div>
            <div className='erweima' id='erweima'>
                <span className='title-text text' >推广二维码</span>
                {pageData.spreadUrl&&<div className='erweima-box' onClick={downloadQrcode}>
                    <QRCode
                        id='qrCode'
                        className='erweima-pic'
                        value={pageData.spreadUrl}
                        size={180}
                        fgColor='#000000'
                    />
                    <div className='mask'>
                        <span className='hover-test'>点击下载</span>
                    </div>
                </div>}
            </div>
        </div>
    );
};



export default PromoteMessage;
