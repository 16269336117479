/*
 * File: Copyright.tsx
 * Desc: 版权信息
 * File Created: 2020-04-12 22:50:33
 * Author: chenghao
 * ------
 * Copyright 2020 - present, karakal
 */
import React from 'react';
import '../styles/breadcrumb.less'
const BreadCrumb = (props:any) => {
    return (
        <div className='breadcrumb-box'>
            <p className='line1'>{`主页 / ${props.route.title}`}</p>
            <p className='line2'>{props.route.title}</p>
        </div>
    );
};

export default BreadCrumb;
