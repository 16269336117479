/**ct
 * 后端：yank;
 * 前端：xmen;
 * 登录接口地址：http://gw.dev.douqiu.run/doc.html#/qiutx-passport/OAuth2%E7%9B%B8%E5%85%B3%E6%93%8D%E4%BD%9C/agentLoginUsingPOST
 */
import React, { useEffect, useState } from 'react';
import { Button, Form, Input } from 'antd';
import './style/login-home-page.less';

const PromoteMessage = (props:any) => {

    return (
        <div className="login-home-page">
            推广信息页面
        </div>
    );
};

export default PromoteMessage;
