import FingerprintJS from '@fingerprintjs/fingerprintjs';
 import { divide, filter, floor, get, isEmpty, keys, map, trim, zipObject, throttle } from 'lodash-es';
import { message } from 'antd';
import { fromPromise } from 'rxjs/internal-compatibility';
export function clearObjEmptyParams(data: any) {
    let tempData = Object.assign({}, data);

    Object.keys(tempData).forEach((item) => {
        if (tempData[item] === '' || tempData[item] === null || tempData[item] === undefined) {
            delete tempData[item];
        }
    });
    return tempData;
}

// 获取设备id
export async function getDeviceId(): Promise<string> {
    let visitorId = localStorage.getItem('deviceId');
    if (!visitorId) {
        const fp = await FingerprintJS.load();

        const result = await fp.get();

        visitorId = result.visitorId;
        localStorage.setItem('deviceId', visitorId);
    }
    return visitorId;
}

export const getDeviceIdObservable = fromPromise(getDeviceId());
export const throttleError = throttle(
    function () {
        message.error('连接超时，请重试！');
    },
    1000,
    { leading: true, trailing: false }
);

export function getBaseUrl(): string {
    return '';
    // 判断环境直接请求网关，不走nginx转发
    console.log(process.env.NODE_ENV);
    if (process.env.NODE_ENV === 'production') {
        // option.url = 'https://gw.dqiu.com' + option.url;
        return 'https://api.shtrblgd.com'; //华为CDN
        // option.url = 'https://api.dqiuhhh.com' + option.url; //incupsula的CDN
        // option.url = 'https://gw.dqiu.co' + option.url; //不走cdn
    } else if (process.env.NODE_ENV === 'development') {
        return 'http://gw.test.dqiu66.com';
    } else {
        return '/gw';
    }
}
