/**
 * Created by hao.cheng on 2017/4/16.
 */
import React from 'react';
import { httpService } from '../../modules/common/services/http.service';
import { storageService } from '../../modules/common/services/storage.service';
import { Table, Button, Row, Col, Card, Form, DatePicker, Input, message } from 'antd';
import './style/member.less';
import moment from 'moment';
const { RangePicker } = DatePicker;
const columns = [
    {
        title: '昵称',
        dataIndex: 'nickname',
        render: (text: any, record: any) => (
            <a href={record.url} target="_blank" rel="noopener noreferrer">
                {text}
            </a>
        ),
    },
    {
        title: '手机号',
        dataIndex: 'mobile',
    },
    {
        title: '累计充值',
        dataIndex: 'rechargeAmount',
        render: (text: any) => text && +text / 100,
    },
    {
        title: '注册时间',
        dataIndex: 'createTime',
    },
    {
        title: '最后在线',
        dataIndex: 'lastOnlineTime',
    },
    {
        title: '贡献佣金(元)',
        dataIndex: 'contributionCommission',
        render: (text: any) => text && +text / 100,
    },
];

class Member extends React.Component {
    state = {
        loading: false,
        data: [],
        startTime: '',
        endTime: '',
        date: '',
        nickname: '',
        datePickList: [
            { date: '今日', dateNum: 0 },
            { date: '昨日', dateNum: -1 },
            { date: '近3天', dateNum: -2 },
            { date: '近7天', dateNum: -6 },
            { date: '近30天', dateNum: -29 },
        ],
        cur: 3,
    };

    componentDidMount() {
        /**
         * @param 初始化加载数据
         */
        this.selectDate(-6, 3);
    }
    /**
     * @param 获取数据
     */
    queryData = (): void => {
        let { startTime, endTime, nickname } = this.state;
        if (startTime) startTime = startTime + ` 00:00:00`;
        if (endTime) endTime = endTime + ` 23:59:59`;

        let uid = storageService.getItem('uid');

        httpService
            .ajax({
                url: `/qiutx-support/v1/agent/invite/users?nickname=${nickname}&startTime=${startTime}&endTime=${endTime}`,
                method: 'get',
                headers: uid
                    ? {
                          'x-user-header': JSON.stringify({ uid }),
                      }
                    : {},
            })
            .subscribe((res: any) => {
                if (res.code !== 200) return message.error(res.msg);
                this.setState({
                    data: res.data,
                    loading: false,
                });
            });
    };

    renderPickTime = () => (
        <div className="datePick_container">
            注册日期：
            {this.state.datePickList.map((item, index) => (
                <div
                    className={`datePick ${this.state.cur === index ? 'cur' : ''}`}
                    onClick={() => this.selectDate(item.dateNum, index)}
                >
                    {item.date}
                </div>
            ))}
        </div>
    );

    selectDate(num?: number, index?: number) {
        function format2(num: number) {
            return Number(num) < 10 ? `0${num}` : num;
        }

        function getMoveDay(AddDayCount: number) {
            const today = new Date();
            today.setDate(today.getDate() + AddDayCount);
            return `${format2(today.getFullYear())}-${format2(today.getMonth() + 1)}-${format2(
                today.getDate()
            )}`;
        }
        if (num || num === 0) {
            if (num === -1)
                this.setState(
                    (state) => {
                        return {
                            startTime: getMoveDay(num),
                            endTime: getMoveDay(num),
                            cur: 1,
                        };
                    },
                    () => this.queryData()
                );
            else
                this.setState(
                    (state) => {
                        return {
                            cur: index,
                            startTime: getMoveDay(num),
                            endTime: moment().format('YYYY-MM-DD'),
                        };
                    },
                    () => this.queryData()
                );
        } else
            this.setState(
                (state) => {
                    return {
                        startTime: '',
                        endTime: '',
                        nickname: '',
                        cur: null,
                    };
                },
                () => this.queryData()
            );
    }

    setTime = (dates: any, dateStrings: [string, string]): void => {
        let [startTime, endTime] = dateStrings;
        this.setState(
            (state) => {
                return {
                    startTime,
                    endTime,
                };
            },
            () => this.queryData()
        );
    };
    render() {
        const { loading, startTime, endTime, data, nickname } = this.state;
        let showTime: any = [moment(startTime), moment(endTime)];
        if (!startTime || !endTime) showTime = ['', ''];

        return (
            <div className="gutter-example member">
                <Row gutter={16}>
                    <Col className="gutter-row" md={24}>
                        <div className="gutter-box">
                            <Card title="" bordered={false}>
                                <Form onFinish={this.queryData} className="flexBetween form">
                                    <div className="flexBetween">
                                        <div className="selectTime">
                                            {this.renderPickTime()}
                                            {startTime && endTime && (
                                                <div className="desc">
                                                    {`已筛选结果：` +
                                                        startTime +
                                                        ' 00:00:00' +
                                                        '~' +
                                                        endTime +
                                                        ' 23:59:59'}
                                                </div>
                                            )}
                                        </div>

                                        <RangePicker
                                            onChange={this.setTime}
                                            value={showTime}
                                            style={{ height: '32px', width: '256px' }}
                                        />

                                        <div className="nicknameInput">
                                            昵称：
                                            <Input
                                                style={{ width: '176px', height: '32px' }}
                                                size="large"
                                                placeholder="请输入昵称"
                                                onChange={(e) =>
                                                    this.setState({ nickname: e.target.value })
                                                }
                                                value={nickname}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <Button
                                            type="primary"
                                            disabled={loading}
                                            loading={loading}
                                            htmlType="submit"
                                        >
                                            查询
                                        </Button>
                                        <Button
                                            onClick={() => this.selectDate()}
                                            disabled={loading}
                                            loading={loading}
                                        >
                                            重置
                                        </Button>
                                    </div>
                                </Form>
                                <div style={{ paddingBottom: '25px' }}>
                                    <Table columns={columns} dataSource={data} pagination={false} />
                                </div>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}

export default Member;
