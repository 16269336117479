import qs from 'qs';
import md5 from 'blueimp-md5';
// @ts-ignore
import sha1 from 'js-sha1';
import { HttpRequestHeader } from 'antd/lib/upload/interface';
const BaseConfig = Object.freeze({
  version: '1.4.1',
  clientType: 'web',
  signKey: '2567a5ec9705eb7ac2c984033e06189d',
  successCode: '6000',
  loginOutCode: '6001',
  forbiddenCode: '6003',
  dynamicErrCode: '6012',
  nameSpace: {
    matchFilter: 'matchFilter',
    user: 'user',
    home: 'home',
  },
  FiveMajorMatch: ['英超', '意甲', '西甲', '德甲', '法甲'], // 五大赛事
  OBSHost: 'https://sta-fe.pal6s2.com',
});

export function sign(url: string, data: any, method: string, contentType: string): HttpRequestHeader {
  let timestamp = new Date().getTime();
  let signStr = `key=${BaseConfig.signKey}&t=${timestamp}`;
  let signStr2 = obj2str(url, normalizeData(url, data), method, contentType);

  if (signStr2) {
    signStr = signStr2 + '&' + signStr;
  }

  const token = localStorage.getItem('token');

  return {
    'version': BaseConfig.version,
    'client-type': 'web',
    'sign': sha1(md5(signStr)),
    't': timestamp + '',
    'r': createNonceStr(36),
    'authorization': token ? `Bearer ${token}` : 'Basic YXBwOmFwcA==',
  };
}

function obj2str(url: string, data: any, method: string, contentType: string) {
  if (
    method.toLocaleUpperCase() === 'GET' ||
    (contentType && contentType.indexOf('application/x-www-form-urlencoded') > -1)
  ) {
    let keys = Object.keys(data).sort();
    return keys.map((key) => `${key}=${data[key]}`).join('&');
  } else {
    if (!Object.keys(data).length) return '';
    return JSON.stringify(data);
  }
}

function normalizeData(url: string, data: any) {
  let tempData = Object.assign({}, data);
  const splitUrl = url.split('?');
  const queryParams = splitUrl[1];

  if (queryParams) {
    tempData = qs.parse(queryParams);
  }
  return clearObjEmptyParams(tempData);
}

function createNonceStr(len: number) {
  const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
  const uuid = [];
  const radix = chars.length;
  if (len) for (let i = 0; i < len; i++) uuid[i] = chars[0 | (Math.random() * radix)];
  return uuid.join('');
}
function clearObjEmptyParams(data: any) {
  let tempData = Object.assign({}, data);

  Object.keys(tempData).forEach((item) => {
    if (tempData[item] === '' || tempData[item] === null || tempData[item] === undefined) {
      delete tempData[item];
    }
  });
  return tempData;
}